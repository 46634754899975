import styled from "styled-components";
import { IIconContainer } from "./types";

export const IconContainer = styled.div<IIconContainer>`
  svg {
    * {
      color: ${({ color }) => color};
    }
  }
`;

export const IconSpanContainer = styled.span<IIconContainer>`
  svg {
    * {
      color: ${({ color }) => color};
    }
  }
`;
